import { useSelector } from "react-redux";
import {
  SelectCurrentFilters,
  SelectAllFilters,
  SelectCurrentProject,
} from "../../features/projects/projectSlice";
import { useGetValuesQuery } from "../../features/report/valuesApiSlice";
import ScatterDoubleContestLayout from "../graph/ScatterDoubleContestLayout";
import Loader from "../Loader";
import FilterBar from "../../layout/FilterBar";
import { splitArray } from "../functions/functions";

const Values = () => {
  const selectedProject = useSelector(SelectCurrentProject);
  const selectedFilters = useSelector(SelectCurrentFilters);
  const filterArray = useSelector(SelectAllFilters);

  const {
    data: scatterData,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetValuesQuery(selectedProject);

  function extract_data(data) {
    if (!data) return [];
    return data.map((item) => ({
      target: item["filter_value"],
      performance_importance: item.performance_importance,
      performance_satisfaction: item.performance_satisfaction,
      rater_pct: item.rater_pct,
      scelto: item.scelto,
    }));
  }

  function filterData(data, filter) {
    let filter_value = filter === "costrutto" ? "overall" : filter;
    let newData = splitArray(
      extract_data(data[filter_value]),
      "Presence versus Importance"
    );
    return newData;
  }

  let inputData = [[], []];
  if (isLoading) {
    return <Loader />;
  } else if (isSuccess) {
    inputData = filterData(scatterData.items, filterArray[selectedFilters[0]]);
  } else if (isError) {
    return <p>{JSON.stringify(error)}</p>;
  }

  return (
    <>
      <FilterBar selectedFilters={selectedFilters} />

      <div className="container">
        <div className="row">
          <ScatterDoubleContestLayout
            dataset={inputData[0]}
            titles={inputData[1]}
            ytitle={"Presence "}
            xtitle={"Importance"}
          />
        </div>
      </div>
    </>
  );
};

export default Values;
