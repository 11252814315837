import {ScatterPlotlyTrendline} from "./ScatterPlotlyTrendline";


const ScatterPlotlyTrendlineLayout = ({
  objectArray = [0],
  gradients = {gradients},
  full_width = false,
  title       = 'Title of the object',
  dataset,
  xtitle      = 'Title x-axis',
  ytitle      = 'Title y-axis',
  marker=false,

  }) => {

    let col_class = 'col-lg-6 mt-3'
    if (full_width) {
      col_class = 'col-lg-12 mt-3'
    }

    return (
      <>
      {
        objectArray.map((e, i) => (
          <div key={'object_' + i} className={col_class}>
          <div className="box">
            <div className="box-title">{title}</div>
              <ScatterPlotlyTrendline
                  dataset = {dataset}
                  gradients = {gradients}
                  xtitle  = {xtitle}
                  ytitle  = {ytitle}
                  marker  = {marker}
              />
            </div>
          </div>
        ))
      }
      </>
)}

export default ScatterPlotlyTrendlineLayout
