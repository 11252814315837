import { ScatterDoubleContest } from "./ScatterDoubleContest";

const ScatterDoubleContestLayout = ({
  dataset,
  titles = ["Title of the object"],
  xtitle = "Title x-axis",
  ytitle = "Title y-axis",
  full_width= false,
}) => {
  let col_class = "col-lg-6 mt-3";
  if (full_width) {
    col_class = "col-lg-12 mt-3";
  }

  if (typeof titles === "undefined") {
    var titles = [];
    dataset.forEach((value, index) => {
      titles[index] = `Title ${index + 1}`;
    });
  }

  let objectArray = Array.from(Array(dataset.length).keys());

  return (
    <>
      {dataset &&
        objectArray.map((e, i) => (
          <div key={"object_" + i} className={col_class}>
            <div className="box">
              <div className="box-title">{titles[i]}</div>
              <ScatterDoubleContest
                dataset={dataset[i]}
                xtitle={xtitle}
                ytitle={ytitle}
              />
            </div>
          </div>
        ))}
    </>
  );
};

export default ScatterDoubleContestLayout;
