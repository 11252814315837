import * as Plot from "@observablehq/plot";
import * as d3 from "d3";
import { useEffect, useRef } from "react";
import { findWeightedLineByLeastSquares } from "../functions/functions";

export const ScatterDoubleContest = ({
  dataset,
  xtitle = "Title of X-axis",
  ytitle = "Title of Y-axis",
}) => {
  const containerRef = useRef();

  // toggle between showing the -100 to 100 range or extending for the data range
  let show_full_axis_range = true;

  // determine the number of labels displayed by default, in case we link it to a numeric input
  let min_pct_value = 0.3;

  useEffect(() => {
    if (dataset === undefined) return;

    const plot = Plot.plot({
      marginBottom: 60,
      marginLeft: 80,
      x: {
        domain: show_full_axis_range
          ? [-1, 1]
          : d3.extent(dataset.map((d) => d.performance_importance)),
        tickFormat: d3.format(".0%"),
      },
      y: {
        domain: show_full_axis_range
          ? [-1, 1]
          : d3.extent(dataset.map((d) => d.performance_satisfaction)),
        tickFormat: d3.format(".0%"),
      },
      r: { domain: [0, 100], percent: true, range: [3, 18] },
      aspectRatio: show_full_axis_range ? 1 : null,

      marks: [
        Plot.axisX({
          anchor: "bottom",
          labelAnchor: "center",
          labelArrow: "None",
          fontSize: "14px",
          tickSpacing: 100,
          label: xtitle,
        }),
        Plot.axisY({
          anchor: "left",
          labelAnchor: "center",
          labelArrow: "None",
          fontSize: "14px",
          tickSpacing: 100,
          label: ytitle,
        }),
        // axes lines
        Plot.ruleX([0]),
        Plot.ruleY([0]),
        // linear regression line
        Plot.line(
          // this should be in the helper script, we can also compute this outside if it's simpler for you
          findWeightedLineByLeastSquares(
            dataset.map((d) => d.performance_importance),
            dataset.map((d) => d.performance_satisfaction),
            dataset.map((d) => d.rater_pct)
          ).pred_values
        ),
        // main scatterplot, with points sized by rater_pct
        Plot.dot(dataset, {
          x: "performance_importance",
          y: "performance_satisfaction",
          r: "rater_pct",
          fill: "lightblue",
          stroke: "white",
          tip: true,
          title: (d) =>
            `${d.scelto}\n\nx: ${d3.format(".1%")(
              d.performance_importance
            )}\ny: ${d3.format(".1%")(
              d.performance_satisfaction
            )}\nscelto da: ${d3.format(".0%")(d.rater_pct)}`,
        }),
        // text on top of each point, representing the rater_pct
        Plot.text(dataset, {
          x: "performance_importance",
          y: "performance_satisfaction",
          text: (d) => d3.format(".0f")(d.rater_pct * 100),
        }),
        // explicit labels only on items rated by more than min_pct_value
        Plot.text(dataset, {
          x: "performance_importance",
          y: "performance_satisfaction",
          text: "scelto",
          textAnchor: "start",
          dx: 5,
          dy: 5,
          filter: (d) => d.rater_pct >= min_pct_value,
        }),
      ],
    });

    containerRef.current.append(plot);
    return () => plot.remove();
  }, [dataset]);

  return <div ref={containerRef} />;
};
